import { CLASS_MATERIALS } from '../types/class-materials'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLASS_MATERIALS.SET_ARCHIVES:
      return {
        ...state,
        setArchives: action.payload
      }
    case CLASS_MATERIALS.SET_LINKS:
      return {
        ...state,
        setLinks: action.payload
      }
    case CLASS_MATERIALS.SET_DOWNLOADS:
      return {
        ...state,
        setDownloads: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
