import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './assets/base.scss'
import 'helpers/library-icons'
import Notification from 'components/shared/Notification'
import configureStore from './config/configureStore'
import ScrollToTop from './utils/ScrollToTop'
import Routes from './routes'
// import { refreshToken } from 'store/actions/user'

function App() {
  const store = configureStore()
  useEffect(() => {
    // refreshToken()
  })
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ScrollToTop>
          <Routes />
          <Notification />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  )
}
export default App
