/* eslint-disable no-unused-vars */
import api from 'config/api'
import jwt from 'jsonwebtoken'
import { URL_API } from 'config/constantes'
import {
  token as accessToken,
  disciplina as saveDisciplina,
  user as saveUser
} from 'utils/local-storage'
import { USER } from '../types/user'
import {
  requestFailure,
  requestLoading,
  requestSuccess
} from './helpers/status-request'
import { showNotification } from './notification'
import { setDisciplinas, setSelectedDiscipline } from 'store/actions/discipline'
import { setPersonalData } from './personal-data'
export const authentication = (usuario, senha) => async dispatch => {
  dispatch(requestLoading(true))
  try {
    const response = await api.post(`${URL_API}/auth/login`, {
      user: usuario,
      password: senha
    })

    const token = response.data.token
    accessToken.set(token)

    dispatch(getDisciplinas(token))
    dispatch(requestSuccess(true))
  } catch (error) {
    dispatch(requestFailure(error))
    dispatch(
      showNotification({
        open: true,
        message: 'Usuario ou senha incorretos',
        style: 'error'
      })
    )
  } finally {
    dispatch(requestLoading(false))
  }
}

export const getDisciplinas = token => async dispatch => {
  await api
    .get(`${URL_API}/curso/disciplines`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      }
    })

    .then(response => {
      dispatch(
        setDisciplinas({
          discipline: response.data.disciplinas,
          grupo: response.data.grupo,
          course:
            response.data.grupo === 2
              ? response.data?.disciplinas[
                  Object.keys(response.data?.disciplinas)[0]
                ][0].disciplina
              : response.data?.aluno?.cursoNome
        })
      )
      dispatch({
        type: USER.LOGIN,
        payload: {
          info: {
            ...jwt.decode(token),
            grupo: response.data.grupo,
            progresso: response?.data?.aluno?.progresso
          },
          token
        }
      })
    })
    .catch(() => {
      dispatch(logout())
    })
}

export const setUserBoxButtonsStatus = payload => ({
  type: USER.SET_BUTTONS,
  payload
})

// export const refreshToken = async () => {
//   const intervalId = setInterval(getNewToken, 90000)
//   return () => clearInterval(intervalId)
// }

const getNewToken = async () => {
  // if (accessToken.get()) {
  //   await api
  //     .post(`${URL_API}/token/refresh`, {
  //       oldToken: accessToken.get()
  //     })
  //     .then(response => {
  //       accessToken.set(response.data.token)
  //     })
  //     .catch(() => {
  //       logout()
  //     })
  // }
}

export const getStatistic = (user, discipline) => async dispatch => {
  try {
    const response = await api.get(
      `${URL_API}/curso/statistics/${discipline.selectedDiscipline.id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${user.token}`
        }
      }
    )

    dispatch(
      setStatistic({
        students: response.data.total_alunos.count,
        posts: response.data.qtd_postagem.count,
        questions: response.data.qtd_portfolios.count,
        toCorrect: response.data.qtd_portfolios_corrigidos.count
      })
    )
  } catch (error) {
    dispatch(requestFailure(error))
    dispatch(
      showNotification({
        open: true,
        message: 'Problema na autenticação',
        style: 'error'
      })
    )
  }
}

export const setStatistic = payload => ({
  type: USER.SET_STATISTIC,
  payload
})

export const logout = () => async dispatch => {
  dispatch(
    setDisciplinas({
      discipline: [],
      grupo: '',
      course: ''
    })
  )

  dispatch(setSelectedDiscipline(''))

  dispatch(
    setPersonalData({
      nome: '',
      username: '',
      cpf: '',
      rg: '',
      nasc: '',
      email: '',
      cel: '',
      grupo: ''
    })
  )
  saveDisciplina.remove()
  accessToken.remove()

  dispatch({
    type: USER.LOGOUT,
    payload: {}
  })
}
