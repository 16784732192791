/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from 'store/actions/user'
import api from 'config/api'
import { paths } from 'utils/pathnames'

export default function ProviderValidation({ children }) {
  const user = useSelector(state => state.User)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const discipline = useSelector(state => state.Discipline?.selectedDiscipline)

  React.useLayoutEffect(() => {
    const callFunc = async () => {
      if (location.pathname === '/recuperar-senha') {
        history.push('/recuperar-senha')
      } else if (location.pathname.split('/')[1] === 'definir-nova-senha') {
        history.push(location.pathname)
      } else if (user.token) {
        try {
          if (location.pathname === '/meu-perfil') {
            history.push('/meu-perfil')
          } else if (!discipline) {
            history.push('/disciplinas')
          } else if (
            (location.pathname === '/disciplinas' && discipline) ||
            location.pathname === '/login'
          ) {
            history.push('/dashboard')
          } else {
            if (
              paths.get().filter(path => path === location.pathname).length ===
              0
            )
              history.push('/dashboard')
          }
        } catch (error) {
          dispatch(logout())
        }
      } else {
        history.push('/login')
      }
    }

    callFunc()
  }, [user.token])
  return <>{children}</>
}
