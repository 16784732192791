import { disciplina } from 'utils/local-storage'
import { DISCIPLINE } from '../types/discipline'

const initialState = {
  list: disciplina.get() || undefined,
  filteredListDisciplines: disciplina.get()
    ? disciplina.get()[Object.keys(disciplina.get())[0]].disciplina
    : false || undefined,
  filteredListCourses: disciplina.get()
    ? disciplina.get()[Object.keys(disciplina.get())[0]]
    : false || undefined,
  year: disciplina.get()
    ? Object.keys(disciplina.get())[0]
    : false || undefined,
  course: disciplina.get()
    ? disciplina.get()[Object.keys(disciplina.get())[0]][0].nome
    : false || undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DISCIPLINE.SET_DISCIPLINE:
      return {
        ...state,
        list: action.payload
      }
    case DISCIPLINE.SET_YEAR:
      if (action.payload === '') {
        return {
          ...state,
          year: '',
          filteredListCourses: [],
          filteredListDisciplines: []
        }
      } else {
        return {
          ...state,
          year: action.payload,
          filteredListCourses: state?.list ? state?.list[action.payload] : [],
          filteredListDisciplines:
            state?.list[action.payload][0]?.professor === undefined &&
            state?.list[action.payload][0].disciplina
        }
      }

    case DISCIPLINE.SET_COURSE:
      if (action.payload === '') {
        return {
          ...state,
          course: action.payload,
          filteredListDisciplines: []
        }
      } else {
        return {
          ...state,
          course: action.payload,
          filteredListDisciplines:
            (state.list[Object.keys(state.list)[0]][0].professor ===
              undefined &&
              state.filteredListCourses?.filter(
                discipline => discipline.nome === action.payload
              )[0].disciplina) ||
            undefined
        }
      }
    case DISCIPLINE.SET_FILTERED_LIST_DISCIPLINES:
      return {
        ...state,
        filteredListDisciplines: action.payload
      }
    case DISCIPLINE.SET_SELECTED_DISCIPLINE:
      return {
        ...state,
        selectedDiscipline: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
