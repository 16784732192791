import axios from 'axios'
import env from 'environment'
import https from 'https'

const api = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
    Agent: false,
    checkServerIdenty: () => undefined
  }),
  baseURL: env.API_URL
})

export default api
