export const ACESSOS = {
  ALUNO: 1,
  PROFESSOR: 2,
  TUTOR: 3,
  PROFESSOR_TUTOR: 4,
  TODOS: [1, 2, 3, 4]
}

const PREFIX = '@Unigran'
export const LOCALSTORAGE = {
  TOKEN: `${PREFIX}/token`,
  DISCIPLINA: `${PREFIX}/disciplina`,
  USER: `${PREFIX}/user`
}

export const URL_API = 'https://backend-pos.unigran.br:3000'
// export const URL_API = 'http://172.16.0.124:3000'
// export const URL_API = 'http://localhost:3000'
