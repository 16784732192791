import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import unigranLoader from 'assets/images/unigran/unigran-loader.png'

const SuspenseLoading = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
              <div className="d-flex align-items-center flex-column px-4">
                <img src={unigranLoader} alt="Unigran Loader" />
              </div>
              <div className="text-muted font-size-xl text-center pt-3">
                Aguarde a pagina esta carregando.
                <span className="font-size-lg d-block text-dark">
                  A Unigran agradece pela visita!
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default SuspenseLoading
