import { token } from 'utils/local-storage'
import { USER } from '../types/user'

const defaultState = {
  token: '',
  info: {
    grupo: ''
  },
  statistic: {
    students: 23,
    posts: 46,
    questions: 59,
    toCorrect: 73
  }
}

const initialState = {
  token: token.get() || '',
  options: {
    disciplina: false,
    polo: false,
    funcao: false
  },
  info: {
    ...token.decrypt(),
    grupo: ''
  },
  statistic: {
    students: 23,
    posts: 46,
    questions: 59,
    toCorrect: 73
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER.LOGIN: {
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.info,
          grupo: action.payload.info.grupo
        },
        token: action.payload.token
      }
    }
    case USER.SET_DISCIPLINA: {
      return {
        ...state,
        options: {
          ...state.options,
          disciplina: action.payload
        }
      }
    }
    case USER.SET_STATISTIC: {
      return {
        ...state,
        statistic: {
          ...action.payload
        }
      }
    }
    case USER.LOGOUT:
      return defaultState
    default:
      return state
  }
}
