import ThemeOptions from './theme-options'
import Request from './request'
import User from './user'
import Notification from './notifications'
import Discipline from './discipline'
import PersonalData from './personal-data'
import Grades from './grades'
import classMaterials from './class-materials'
export default {
  Notification,
  ThemeOptions,
  Request,
  User,
  Discipline,
  PersonalData,
  Grades,
  classMaterials
}
