export const USER = {
  LOGIN: '@USER/LOGIN',
  LOGOUT: '@USER/LOGOUT',
  SET_DISCIPLINA: '@USER/SET_DISCIPLINA',
  SET_POLO: '@USER/SET_POLO',
  SET_FUNCAO: '@USER/SET_FUNCAO',
  SET_BUTTONS: '@USER/SET_BUTTONS',
  SET_STATISTIC: '@USER/SET_STATISTIC',
  SET_USER: '@USER/SET_USER'
}
