import { REQUEST } from '../types/helpers'

const initialState = {
  loading: false,
  success: false,
  failure: {},
  denied: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST.STATUS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case REQUEST.STATUS_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case REQUEST.STATUS_FAILURE:
      return {
        ...state,
        failure: action.payload
      }
    case REQUEST.STATUS_DENIED:
      return {
        ...state,
        denied: action.payload
      }
    default:
      return state
  }
}
