import { NOTIFICATION } from '../types/notifications'

const initialState = {
  open: false,
  vertical: 'top',
  horizontal: 'center',
  style: 'success',
  message: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}
