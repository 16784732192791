import { DISCIPLINE } from '../types/discipline'
import {
  // token as accessToken,
  disciplina as saveDisciplina
} from 'utils/local-storage'

export const setDisciplinas =
  ({ discipline, grupo, course }) =>
  async dispatch => {
    await saveDisciplina.set(discipline)

    dispatch({
      type: DISCIPLINE.SET_DISCIPLINE,
      payload: discipline
    })

    const year = Object.keys(discipline)[0] ? Object.keys(discipline)[0] : ''
    dispatch(setYear(year))

    let courseAux = ''
    if (grupo === 2) {
      courseAux = discipline[year][0].nome
    } else {
      courseAux = course
    }

    dispatch(setCourse(courseAux))
  }

export const setYear = year => {
  return {
    type: DISCIPLINE.SET_YEAR,
    payload: year
  }
}

export const setCourse = course => {
  return {
    type: DISCIPLINE.SET_COURSE,
    payload: course
  }
}

export const setFilteredListDisciplines = disciplines => {
  return {
    type: DISCIPLINE.SET_FILTERED_LIST_DISCIPLINES,
    payload: disciplines
  }
}

export const setSelectedDiscipline = discipline => {
  return {
    type: DISCIPLINE.SET_SELECTED_DISCIPLINE,
    payload: discipline
  }
}
