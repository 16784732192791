import jwt from 'jsonwebtoken'
import { LOCALSTORAGE } from 'config/constantes'

export const token = {
  set: token => {
    localStorage.setItem(LOCALSTORAGE.TOKEN, token)
  },
  get: () => localStorage.getItem(LOCALSTORAGE.TOKEN),
  remove: () => {
    localStorage.removeItem(LOCALSTORAGE.TOKEN)
  },
  decrypt: () => jwt.decode(localStorage.getItem(LOCALSTORAGE.TOKEN))
}

export const user = {
  set: user => {
    localStorage.setItem(LOCALSTORAGE.USER, JSON.stringify(user))
  },
  get: () => JSON.parse(localStorage.getItem(LOCALSTORAGE.USER)),
  remove: () => {
    localStorage.removeItem(LOCALSTORAGE.USER)
  }
}

export const disciplina = {
  set: discipline =>
    localStorage.setItem(LOCALSTORAGE.DISCIPLINA, JSON.stringify(discipline)),
  get: () => JSON.parse(localStorage.getItem(LOCALSTORAGE.DISCIPLINA)),
  remove: () => localStorage.removeItem(LOCALSTORAGE.DISCIPLINA)
}
