import { REQUEST } from '../../types/helpers'

export const requestLoading = payload => ({
  type: REQUEST.STATUS_LOADING,
  payload
})
export const requestSuccess = payload => ({
  type: REQUEST.STATUS_SUCCESS,
  payload
})
export const requestFailure = payload => ({
  type: REQUEST.STATUS_FAILURE,
  payload
})
export const requestDenied = payload => ({
  type: REQUEST.STATUS_DENIED,
  payload
})
