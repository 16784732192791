import React, { Suspense, lazy } from 'react'
import { Switch, Redirect, useLocation, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { ThemeProvider } from '@material-ui/styles'
import SuspenseLoading from '../helpers/suspense-loading'
import MuiTheme from '../theme'
import ProviderValidation from './auth/provider-validation'

const Login = lazy(() => import('./login'))
const RecuperarSenha = lazy(() => import('./recuperar-senha'))
const Disciplinas = lazy(() => import('./disciplinas'))
const Dashboard = lazy(() => import('./dashboard'))
const DefinirNovaSenha = lazy(() => import('./definir-nova-senha'))
const MeuPerfil = lazy(() => import('./meu-perfil'))

const Routes = () => {
  const location = useLocation()
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <ProviderValidation>
              <Route path={['/login']}>
                <Login
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
              <Route path={['/recuperar-senha']}>
                <RecuperarSenha
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
              <Route path={['/definir-nova-senha']}>
                <DefinirNovaSenha
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
              <Route path={['/disciplinas']}>
                <Disciplinas
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
              <Route path={['/meu-perfil']}>
                <MeuPerfil
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
              <Route
                exact
                path={[
                  '/dashboard',
                  '/area-aluno',
                  '/area-professor',
                  '/avaliacoes/correcao',
                  '/avaliacoes/corrigidas',
                  '/avaliacoes/gabarito',
                  '/avaliacoes/informativos',
                  '/avaliacoes/questoes',
                  '/portfolios',
                  '/notas/consultar',
                  '/notas/portfolio',
                  '/notas/exames',
                  '/notas/provas',
                  '/notas/substitutivas',
                  '/notas/alteracao-notas',
                  '/notas/portfolio',
                  '/material/arquivos',
                  '/programacao/agenda',
                  '/programacao/calendario',
                  '/programacao/plano-ensino',
                  '/sala-virtual/atividades',
                  '/sala-virtual/forum',
                  '/quadro-avisos',
                  '/videos'
                ]}
              >
                <Dashboard
                  variants={pageVariants}
                  transition={pageTransition}
                  location={location}
                />
              </Route>
            </ProviderValidation>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
