import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showNotification } from 'store/actions/notification'

export default function Notifications() {
  const notification = useSelector(state => state.Notification)
  const dispatch = useDispatch()

  React.useEffect(() => {
    let timer = null
    if (notification.open === true) {
      timer = setTimeout(() => {
        dispatch(showNotification({ open: false }))
      }, 4500)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [dispatch, notification.open])

  const handleClose = () => {
    dispatch(showNotification({ open: false }))
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: notification.vertical,
        horizontal: notification.horizontal
      }}
      key={`${notification.vertical},${notification.horizontal}`}
      open={notification.open}
    >
      <Alert severity={notification.style} onClose={handleClose}>
        {notification.message}
      </Alert>
    </Snackbar>
  )
}
