import { GRADES } from '../types/grades'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case GRADES.SET_GRADES:
      return {
        ...state,
        ...action.payload
      }
    case GRADES.SET_GRADES_TO_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}
