import { PERSONAL_DATA } from '../types/personal-data'

const initialState = {
  nome: null,
  username: null,
  cpf: null,
  rg: null,
  nasc: null,
  email: null,
  cel: null,
  grupo: null,
  access: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PERSONAL_DATA.SET_PERSONAL_DATA:
      return {
        ...state,
        ...action.payload
      }
    case PERSONAL_DATA.SET_PASSWORD:
      return {
        ...state,
        ...action.payload
      }
    case PERSONAL_DATA.SET_ACCESS:
      return {
        ...state,
        access: action.payload
      }
    default:
      return {
        ...state
      }
  }
}
