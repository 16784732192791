import { PERSONAL_DATA } from '../types/personal-data'
import api from '../../config/api'
import { token, token as accessToken } from '../../utils/local-storage'
import { requestFailure } from './helpers/status-request'
import { showNotification } from './notification'
import { URL_API } from 'config/constantes'

export const setPersonalData = ({
  nome,
  username,
  cpf,
  rg,
  nasc,
  email,
  cel,
  grupo
}) => {
  return {
    type: PERSONAL_DATA.SET_PERSONAL_DATA,
    payload: { nome, username, cpf, rg, nasc, email, cel, grupo }
  }
}

export const getPersonalData = async dispatch => {
  const token = await accessToken.get()
  await api
    .get(`${URL_API}/users/personalData`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      dispatch(
        setPersonalData({
          nome: response.data.nome,
          username: response.data.username,
          cpf: response.data.cpf,
          rg: response.data.rg,
          nasc: response.data.data_nascto,
          email: response.data.email,
          cel: response.data.fone_celular,
          grupo: response.data.grupo
        })
      )
    })
    .catch(error => {
      dispatch(requestFailure(error))
    })
}

export const updatePersonalData = async (dispatch, input) => {
  const token = await accessToken.get()
  if (isValidEmail(input.emailprop)) {
    try {
      api.put(
        `${URL_API}/users/updatePersonalData`,
        {
          fone: input.celularprop,
          email: input.emailprop
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      dispatch(
        showNotification({
          open: true,
          message: 'Alterado com sucesso!',
          style: 'success'
        })
      )
    } catch (error) {
      dispatch(
        showNotification({
          open: true,
          message: error.message,
          style: 'success'
        })
      )
    }
  } else {
    dispatch(
      showNotification({
        open: true,
        message: 'Insira um email válido.',
        style: 'error'
      })
    )
  }
}

export const updatePassword =
  (novaSenha, confirmeSenha, senhaAntiga) => async dispatch => {
    if (novaSenha === confirmeSenha) {
      await api
        .put(
          `${URL_API}/users/updatepassword`,
          { oldPassword: senhaAntiga, newPassword: novaSenha },
          {
            headers: {
              Authorization: `Bearer ${token.get()}`
            }
          }
        )
        .then(() => {
          dispatch(
            showNotification({
              open: true,
              message: 'Alterado com sucesso!',
              style: 'success'
            })
          )
        })
        .catch(() => {
          dispatch(
            showNotification({
              open: true,
              message: 'Não foi possível alterar a senha',
              style: 'error'
            })
          )
        })
    } else {
      dispatch(
        showNotification({
          open: true,
          message: 'As senhas não batem!',
          style: 'warning'
        })
      )
    }
  }

export const getAccess = async (dispatch, page) => {
  try {
    const response = await api.get(`${URL_API}/student/getaccess/${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.get()}`
      }
    })
    dispatch({
      type: PERSONAL_DATA.SET_ACCESS,
      payload: response.data
    })
  } catch (error) {
    console.log(error)
  }
}

function isValidEmail(email) {
  /* eslint-disable */
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
